import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '@client-portal/ui/button';
import { goBack } from '@client-portal/helpers/common';
import { IconComponent } from '@client-portal/ui/icon';

@Component({
  selector: 'clp-page-not-found',
  standalone: true,
  imports: [ButtonComponent, IconComponent],
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss',
    '../../../../shared/styles/error-page.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  constructor(private _router: Router) {}

  public goBack(): void {
    goBack(this._router);
  }
}
