<div class="error-page">
  <div class="error-page__container">
    <div class="error-page__title">
      <ui-icon class="error-page__icon" icon="error" filled />
      <h2>Page not found</h2>
    </div>
    <p>The page you are looking for cannot be found.</p>
    <div class="error-page__action">
      <ui-button (handleClick)="goBack()" cssClasses="button-full-width">
        Go back
      </ui-button>
    </div>
  </div>
</div>
